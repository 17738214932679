/*
 * Additional styles for the tab component:
 * `.bordered-active-tab` - Adds border to the active tab
 * `.tabs-highlighted` - Adds background color to the tab container
*/
.nav-tabs {
  [role="tab"].active.bordered-active-tab {
    border: 1px solid var(--falcon-border-color) !important;
    border-bottom: 3px solid var(--falcon-primary) !important;
  }

  &.tabs-highlighted {
    background-color: var(--falcon-body-bg);
  }
}

table.app-table-striped tr:nth-child(odd) {
  background-color: var(--falcon-gray-200);
}

.divide-y>*:not(:last-child) {
  border-bottom: 1px solid var(--falcon-border-color);
}

.hover\:text-primary:hover {
  color: var(--falcon-primary) !important;
}

.hover\:text-secondary:hover {
  color: var(--falcon-secondary) !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

// Reusable OTP input style
.app-otp-input {
  width: 3rem;
  height: 3rem;
  font-size: 1.125rem;
  text-align: center;
  font-weight: bold;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 2px solid;
  border-bottom: 2px solid;

  &:first-child,
  &.otp-input-3 {
    border-radius: 0.75rem 0 0 0.75rem;
  }

  &.otp-input-1,
  &.otp-input-4 {
    border-radius: 0;
  }

  &:last-child,
  &.otp-input-2 {
    border-radius: 0 0.75rem 0.75rem 0;
  }
}

.bump {
  animation: bump 0.3s ease;
  border: 2px solid var(--falcon-primary);
  border-radius: 0.375rem;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}
