#page-login {
  min-height: 100vh;

  .form-wrapper {
    max-width: 480px;
  }

  .page-logo {
    width: auto;
    height: 56px;
    object-fit: contain;
    object-position: center;
  }

  .page-container {
    max-width: 1280px;
  }
}
